module.exports = {
  title: 'Dev Ramblings', // Required
  author: 'Nirdosh Chapagain', // Required
  description: 'Personal Blog',
  primaryColor: '#3498db', // Required
  showHeaderImage: false,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    website: 'https://nirdosh.dev',
    github: 'https://github.com/nirdoshc',
    twitter: 'https://twitter.com/nirdwesh',
  },
  pathPrefix: '/devblog',
  siteUrl: 'https://nirdosh.dev',
};
